import * as React from "react"
import Layout from "../components/layout"


const NotFoundPage = () => {
  return (
    <Layout>
      <title>Not found</title>
    </Layout>
  )
}

export default NotFoundPage
